.sec-footer {
    background-color: black;
    height: 120px;
    
}
.img-logo {
    margin-top: 42px;
    margin-left: 60px;
}

.copy-r > p {
    color: white;
    text-align: right;
    margin-right: 42px;
    margin-top:-30px;
    font-size: 14px;
    font-family: basier_circleregular;
}

/* Mobile screen */
@media screen  and (max-width: 960px){
    .sec-footer {
        height: 100px;
        
    }
    .img-logo {
        margin-top: 30px;
        margin-left: 20px;
    }
    .copy-r > p {
        margin-right: 24px;
        font-family: basier_circleregular;
    }

}